:root {
  --mgi-modal-header-font-size: 22px;
}

:local(.window) {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 350px;
  max-width: 100%;
  min-height: 200px;
  max-height: calc(100vh - 100px);
  overflow: hidden;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 4px rgba(52, 73, 94, 0.1);

  :global(.small) {
    width: 400px;
    min-height: 150px;
  }

  :global(.MuiInputLabel-root) {
    color: #232939;
    font-weight: bold;
  }

  :global(.MuiButtonBase-root) {
    min-height: 50px;
    font-size: 14px;
  }

  :global(.MuiSwitch-switchBase) {
    min-height: auto;
  }

  :global(.MuiButton-contained):not(:global(.MuiButton-containedPrimary)) {
    margin-bottom: -20px;
    color: #8392B8;
    background-color: transparent;

    &:hover, &:focus {
      background-color: transparent;
    }
  }

  :global(.MuiButton-containedPrimary) {
    background-color: #507DED;

    &:hover, &:focus {
      background-color: #608eed;
    }
  }

  :local(.btnClose) {
    position: absolute;
    z-index: 100;
    right: 16px;
    top: 0;
    width: 32px;
    height: 32px;
    border: none;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../../../assets/icon-close-button.svg');
    outline: none;
    cursor: pointer;
    transition: .25s all ease-in-out;

    &:hover, &:focus {
      filter: brightness(0.5);
      background-color: transparent;
    }
  }

  :local(.sticky) {
    position: sticky;
    left: 92%;
  }

  :local(.wideHeader) {
    @media (min-width: 1280px) {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      :local(.img) {
        width: 100px;
        height: 100px;

        img {
          width: 60px;
        }
      }

      :local(.titleWrapper) {
        text-align: left;
        margin-left: 20px;
      }

      :local(.subtitle) {
        max-width: 360px;
      }
    }
  }

  :local(.img) {
    width: 170px;
    height: 170px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: var(--mgi-theme-palette-darkblue);
  }

  :local(.titleWrapper) {
    text-align: center;
  }

  :local(.subtitle) {
    max-width: 250px;
    margin: 0 auto 20px;
    color: var(--mgi-theme-palette-darkgrey);
  }

  :global(.btn-delete) {
    color: var(--mgi-theme-palette-white);
    background-color: var(--mgi-theme-palette-lightred);

    &:hover, &:focus {
      color: var(--mgi-theme-palette-white);
      background-color: var(--mgi-theme-palette-lightpink);
    }
  }

  & > main {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
  }

  & > header {
    text-align: center;
    font-size: var(--mgi-modal-header-font-size);
  }

  & > footer.modal--footer-transparent {
    margin-top: 0;
    background: transparent;
  }

  & > footer.modal--footer-center {
    justify-content: center;
  }
}

.modal--footer-spacer {
  flex: 1;
}

@media (max-width: 769px) {
  :root {
    --mgi-modal-header-font-size: 16px;
  }
}
