* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

html {
  color: var(--mgi-theme-palette-default);
  background: var(--mgi-theme-palette-lightergray);
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  font-size: var(--mgi-theme-font-size);
}

body {
  line-height: normal;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  /* Remove extra space below image */
  display: inline-flex;
  max-width: 100%;
}

p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

strong {
  font-weight: 600;
}

a {
  color: var(--mgi-theme-palette-blue);
  text-decoration: none;
}

h1,
h2,
h3 {
  margin: 0;

  p {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;

  p {
    font-size: 1.125rem;
    line-height: normal;
  }

  p:first-of-type {
    margin-top: calc(0.5 * var(--mgi-spacing));
  }
}

h2 {
  font-size: 1.28rem;
  font-weight: 700;

  p {
    font-size: 1rem;
  }

  p:first-of-type {
    margin-top: calc(0.5 * var(--mgi-spacing));
  }
}

h3 {
  font-size: inherit;
  font-weight: 600;
}

hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom: 1px solid var(--mgi-theme-palette-lightgray);
}

button, button:hover, button:focus, button:active, button:focus-visible {
  border: none;
  outline: none;
}
