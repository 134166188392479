.overlay {
  position: fixed;
  z-index: 1150;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(49, 53, 65, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--mgi-spacing);
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.overlayVisible {
  opacity: 1;
}

.overlayInline {
  position: absolute;
}

.overlaySearch {
  align-items: flex-start;
  > span {
    width: 100%;
    overflow: hidden;
  }
}

.noScroll {
  overflow: hidden !important;
}

.overlayContent {
  max-height: 100%;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-50px);
  transition: all 0.2s ease-in-out;
  background-color: rgba(49, 53, 65, 0.9);
  border-radius: 16px;
}

.overlayContentVisible {
  opacity: 1;
  transform: translateY(0);
}

.modalFixedContent > span {
  max-height: unset;
}

@media screen and (max-width: 959.95px) {
  .modalFixedContent {
    align-items: flex-start;
  }
}

.blurOverlayContent {
  overflow: visible;
  max-width: 65%;
  width: 65%;
  max-height: 65%;
  height: 65%;
}

.blurOverlay {
  backdrop-filter: blur(5px);
  position: absolute;
  background-color: rgba(33, 35, 45, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--mgi-spacing);
}

