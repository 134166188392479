:root {
  --mgi-spacing: 20px;
  --mgi-spacing-l: 0.875rem;

  --mgi-theme-palette-black: #242424;
  --mgi-theme-palette-white: #ffffff;
  --mgi-theme-palette-gray: #818181;
  --mgi-theme-palette-lightgray: #dcdcdc;
  --mgi-theme-palette-lightergray: #edf0f5;
  --mgi-theme-palette-violet: #5c75ff;
  --mgi-theme-palette-blue: #3757ff;
  --mgi-theme-palette-lightblue: #507DED;
  --mgi-theme-palette-azure: #47a8e8;
  --mgi-theme-palette-lighterblue: #ebeeff;
  --mgi-theme-palette-green: #00ab62;
  --mgi-theme-palette-lightgreen: #42d76c;
  --mgi-theme-palette-red: #ff3535;
  --mgi-theme-palette-lightred: #FE7581;
  --mgi-theme-palette-pink: #ff527e;
  --mgi-theme-palette-orange: #f2994a;
  --mgi-theme-palette-yellow: #ffbd00;
  --mgi-theme-palette-lightyellow: #fec649;
  --mgi-theme-palette-lightpink: #fe8188;
  --mgi-theme-palette-darkgrey: #8392b8;
  --mgi-theme-palette-darkblue: #232939;

  --mgi-theme-palette-default: var(--mgi-theme-palette-black);
  --mgi-theme-palette-secondary: var(--mgi-theme-palette-gray);
  --mgi-theme-palette-disabled: var(--mgi-theme-palette-lightgray);
  --mgi-theme-palette-active: var(--mgi-theme-palette-blue);
  --mgi-theme-palette-error: var(--mgi-theme-palette-red);

  --mgi-theme-font-size: 14px;
}
