.debug {
  border: 1px solid red !important;
}

.text-light {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-error {
  color: var(--mgi-theme-palette-error);
}

.background-active {
  background: var(--mgi-theme-palette-active);
  --mgi-theme-palette-secondary: var(--mgi-theme-palette-lightgray);
  color: white;
}

.background-white {
  background-color: var(--mgi-theme-palette-white);
}

.background-disabled {
  background-color: var(--mgi-theme-palette-disabled);
}

.background-transparent {
  background-color: transparent;
}

.svg-active path {
  fill: var(--mgi-theme-palette-active);
}

[class*='border-']:not(.border-transparent) {
  border-style: solid;
  border-width: 1px;
}

.border-active {
  border-color: var(--mgi-theme-palette-active);
}

.loading:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}
@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.threedots:after {
  content: '...';
  margin: 0 3px;
}

.color-red {
  color: var(--mgi-theme-palette-lightred);
}

.color-blue {
  color: var(--mgi-theme-palette-azure);
}

div#beamerLastPostTitle {
  position: absolute;
  display: none !important;
  top: 75px !important;
  right: 10px !important;
  left: auto !important;
  box-shadow: none !important;
  transform: none !important;
}

div#beamerLastPostTitle .active {
  display: flex !important;
}
#beamerLastPostTitle .popper__arrow {
  display: none !important;
}

#beamerWhatsUp .beamer_icon {
  width: 15px;
  height: 15px;
  border: 2px solid var(--mgi-theme-palette-white);
  top: 4px;
  right: 5px;
  color: transparent;
}

